import { Component } from 'react'
import { 
    Switch, 
    Redirect, 
    Route 
} from 'react-router-dom'
import AsyncComponent from '@/components/AsyncComponent/Index'
import { ReactConsumer } from '@/higher-components'

// connect方法的作用：将额外的props传递给组件，并返回新的组件，组件在该过程中不会受到影响
import { connect } from 'react-redux'

/**
 * props:
 *      @pathToChildRoute 传递给子路由的当前路由 path ，对于子组件来说，这就是子组件的父路由 path
 *      @parentRoutePath 从父路由传递过来的父路由 path
 */
class Routers extends Component {

    constructor(props) {
        super(props)
        const { routes, authMenus, pathToChildRoute } = this.props
        this.parentPath = this.filterPath(pathToChildRoute)
        // this.permissionRoutes = routes
        this.permissionRoutes = pathToChildRoute !== '/main' ? 
            routes : 
            // 将 /main 替换掉，后台的权限地址不带 /main 前缀
            this.getPermissionRoutes(routes, authMenus, this.parentPath.replace(/^\/main/, ''))
    }

    //校验当前 route.path 是否在对应的权限路由中
    findPermissionRoute(menus, route, parentPath) {
        let hasPermission = false
        const a = (menus, route, parentPath) => {
            for(let i = 0, len = menus.length; i < len; i++) {
                const menu = menus[i]
                if (menu.routePath === (parentPath + route.path)) {
                    menu.moduleName && (route.name = menu.moduleName) //有name时，设置对应路由name（可视为页面title）
                    hasPermission = true
                    break
                }
                if (menu.children) {
                    a(menu.children, route, parentPath)
                }
            }
        }
        a(menus, route, parentPath)
        return hasPermission
    }

    //获取当前登录用户对应的权限路由集合
    getPermissionRoutes(routes, authMenus, parentPath) {
        if (!authMenus) return routes
        const authRoutes = []
        this.getAuthRoutes(authMenus, routes, parentPath, authRoutes)
        return authRoutes
    }
    // 递归设置权限路由
    getAuthRoutes(authMenus, routes, parentPath, authRoutes) {
        routes.forEach( route => {
            if (!route.isPermission || this.findPermissionRoute(authMenus, route, parentPath)) {
                const authRoute = {}
                authRoutes.push(Object.assign(authRoute, route, {children: []}))
                if (route.children && route.children.length) {
                    this.getAuthRoutes(authMenus, route.children, parentPath + route.path, authRoute.children)
                }
            }
        })
    }

    //避免出现双斜杆地址，如：//index
    filterPath(path) {
        return path.replace(/\/\//g, '/')
    }

    // 校验 sid 有效性
    checkSidValidation(validSidEndTime) {
        const now = +new Date
        return validSidEndTime && validSidEndTime > now
    }

    render() {
        const { validSidEndTime, pathToChildRoute, redirect } = this.props
        const { parentPath, permissionRoutes } = this

        return (
            <AsyncComponent>
                <Switch>
                    <For 
                        each="route"
                        index="index"
                        of={ permissionRoutes } >
                        <Route 
                            key={ route.path } 
                            exact={ !route.unExact }
                            path={ parentPath + route.path } 
                            render={
                                props => {
                                    const { unNeedLogin, path, redirect, name, component: Component, children } = route
                                    const isValidSid = this.checkSidValidation(validSidEndTime) //每次渲染对应路由组件时，校验登录有效期
                                    if (path === '/') {//将根地址转到登录
                                        return <Redirect to={ isValidSid ? '/main' : redirect || '/login' } />
                                    }
                                    if (unNeedLogin) {//该路由不需要登录时
                                        if (isValidSid && path === '/login') {//已登录且为登录页路由时重定向到首页
                                            return <Redirect to={ 
                                                        props.location.state || 
                                                        {
                                                            pathname: '/main',
                                                            state: props.location
                                                        } 
                                                    } /> 
                                        } else {//未登录时，直接返回对应组件
                                            return <Component 
                                                        { ...props } 
                                                        parentRoutePath={ parentPath }
                                                        pathToChildRoute={ parentPath + path } 
                                                        redirect={ redirect && (parentPath + redirect) }
                                                        title={ name }
                                                        routes={ children } />
                                        }
                                    } else {//该路由需要登录时
                                        if (!isValidSid) {//未登录时重定向到登录页
                                            return <Redirect to={{
                                                        pathname: '/login',
                                                        state: props.location
                                                    }} />
                                        } else {//已登录时直接返回对应组件
                                            const WithConsumerComponent = ReactConsumer(Component) //跨组件传值，接收
                                            // Main 路由组件无需接收，因为值就是由 Main 注入的，否则会造成整个页面重载，造成重复渲染
                                            const ResultComponent = (parentPath + path) !== '/main' ? WithConsumerComponent : Component
                                            return <ResultComponent 
                                                        { ...props } 
                                                        parentRoutePath={ parentPath }
                                                        pathToChildRoute={ parentPath + path } 
                                                        redirect={ redirect && (parentPath + redirect) }
                                                        title={ name }
                                                        routes={ children } />
                                        }
                                    }
                                } 
                            } />
                    </For>
                    {/* Redirect 需放在 Switch 标签的最后 */}
                    <Choose>
                        <When condition={ !pathToChildRoute }>
                            <Redirect from="*" to='/404' />
                        </When>
                        <Otherwise>
                            {   
                                permissionRoutes && 
                                permissionRoutes.length && 
                                <Redirect from={ pathToChildRoute } to={ parentPath + (redirect || permissionRoutes[0].path) } /> 
                            }
                        </Otherwise>
                    </Choose>
                    
                </Switch>
            </AsyncComponent>
        )
    }

}

// mapStateToProps：将state映射到组件的props中
const mapStateToProps = ({user}) => {
    const { validSidEndTime, userInfo } = user
    return {
        validSidEndTime,
        // 当前登录账户的权限路由页面集合
        authMenus: userInfo?.auth
    }
}

export default connect(mapStateToProps)(Routers)