import React from 'react';
// import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
// 由于包中的 TransitionGroup 和 CSSTransitionGroupChild 使用了 componentWillMount 或 componentWillReceiveProps ，现有版本已废除，需加 UNSAFE_ 前缀，故加前缀后放至本地
import ReactCSSTransitionGroup from '@/components/react-transition-group/CSSTransitionGroup';

const DEFAULT_DURATION = 1000;
const DEFAULT_TRANSITION_NAME = 'opacity';

class FakeTransitionWrapper extends React.Component
{
	constructor(props)
	{
		super(props);
	}

	render()
	{
		const children = React.Children.toArray(this.props.children);

		return children[0] || null;
	}
}

export default class TransitionAppear extends React.Component
{
	constructor(props)
	{
		super(props);
	}

	render()
	{
		const props = this.props;
		const duration = props.hasOwnProperty('duration') ? props.duration : DEFAULT_DURATION;
        const transitionName = props.transitionName || DEFAULT_TRANSITION_NAME;

		return (
			<ReactCSSTransitionGroup
				component={FakeTransitionWrapper}
				transitionName={transitionName}
				transitionAppear={Boolean(duration)}
				transitionAppearTimeout={duration}
				transitionEnter={false}
				transitionLeave={false}
			>
				{this.props.children}
			</ReactCSSTransitionGroup>
		);
	}
}
