import { Component } from 'react'
import ReactDOM from 'react-dom'
import domready from 'domready'
import App from './App'
import reportWebVitals from './reportWebVitals'

import * as request from './api'
import {
    message
} from 'antd'

// React Component全局挂载
Object.defineProperties(Component.prototype, {
    '$post': {
        value: request.post
    },
    '$put': {
        value: request.put
    },
    '$get': {
        value: request.get
    },
    '$methods': {
        value: request.methods
    },
    '$uploadAction': {
        value: request.uploadAction
    },
    '$message': {
        value: message
    }
})

domready( () => {
    ReactDOM.render(
        <App />,
        document.getElementById('root')
    )
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
