import { Suspense } from "react"
import { Loading3QuartersOutlined } from '@ant-design/icons'

export default function AsyncComponent({children}) {
    return (
        <Suspense 
            fallback={ 
                <div className='wid100 tc pt_20 pb_20 primary size_24'>
                    <Loading3QuartersOutlined spin />
                </div> 
            }>
            { children }
        </Suspense>
    )
}
