import axios from 'axios'

// create an axios instance
const request = axios.create({
    baseURL: '',
    timeout: 1000 * 60,
    withCredentials: true,
    responseType: 'json',
    headers: {
      // 'Content-Type':'application/x-www-form-urlencoded;charset=utf-8'  
      'Content-Type':'application/json;charset=utf-8' 
    },
    transformRequest: [function(data){
      if (typeof data === 'object') return JSON.stringify(data)   
      else if (typeof data === 'string') return data
    }]//利用对应方法转换格式
})

// request interceptor
request.interceptors.request.use(
  config => {
    // Do something before request is sent
    
    return config
  },
  error => {
    // Do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
request.interceptors.response.use(
  response => {
    
    return response
  }, 
  error => {
    
    return Promise.reject(error)
  }
)

export default request
