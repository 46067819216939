import {
    SET_SYSTEM_INFO
} from '../constants/system'
import { STORE_STATE_KEY } from '../common'

const systemInfo = sessionStorage.getItem(`${STORE_STATE_KEY}systemInfo`)

const INITIAL_STATE = {
    systemInfo: systemInfo && JSON.parse(systemInfo),
}

export default function system (state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_SYSTEM_INFO:
            return {
                ...state,
                systemInfo: action.data
            }
        default:
            return state
    }
}