import request from './request'
import { encryptoByDESModeECB } from '@/utils/crypto'
import { Base64 } from 'js-base64'
import md5 from 'js-md5'
import { message } from 'antd'
import { STORE_STATE_KEY } from '@/store/common'

// 请求的一些静态参数 appid appKey
export const httpStaticInfo = {
    appid : '50613912',
    appKey : '14ac20c7a35c06ab93c244543d51bdef',
    proxyPathPrefix: '/api'
}

// 上传接口地址
export const uploadAction = `${httpStaticInfo.proxyPathPrefix}/upload`

//接口地址
export const methods = {
    // sipLogin: 'fw.user.login', //sip登录（已废弃）
    // sipLoginOut: 'fw.user.logout', //退出sip登录（已废弃）
    userLogin: 'user.auth.login', //登录
    userLoginOut: 'user.auth.logout', //退出登录
    getUserInfo: 'user.get.info', //用户信息
    getCaptcha: 'user.get.captcha', //获取短信验证码
    userChangePwd: 'user.update.pwd', //用户修改密码
    setTransNumber: 'fw.set.trans.number', //设置强显号码
    // callRecords: 'fw.user.cdr', //获取通话记录
    callRecords: 'call.log.list', //获取通话记录 update: 2021.09.15
    callCheck: 'call.phone.check', //呼叫验证
    callReport: 'call.user.report', //呼叫统计报表
    loseContactFixList: 'slink.query.list', //分页查询失联修复记录列表
    loseContactFixCallList: 'slink.record.call.list', //获取修复呼叫记录
    loseContactFixSubmit: 'slink.submit.data', //失联修复提交
    slinkCallPhone: 'slink.call.phone', //修复成功发起呼叫客户
    slinkCallRemark: 'slink.call.remark', //修复通话客户信息备注
    packageList: 'app.package.list', //查询套餐列表数据
    productInfo: 'app.product.info', //根据产品编码查询产品详情
    payOrderList: 'app.order.list', //充值订单列表查询
    tradeRecordList: 'product.change.list', //交易记录分页列表查询
    payMentList: 'app.payment.list', //获取支付方式列表
    orderPay: 'app.order.pay', //订单支付
    orderPayQuery: 'app.order.query', //订单支付查询
    emptyCheckList: 'empty.task.list', //空号检测任务记录分页查询
    emptyCheckDetailList: 'empty.task.detail.list', //空号检测任务号码分页列表
    emptyCheckSubmit: 'empty.task.add', //空号检测提交

    callPhoneCheckList: 'phone.check.task.list', //号码检测任务记录分页查询
    callPhoneCheckDetailList: 'phone.check.detail.list', //号码检测任务号码分页列表
    callPhoneCheckSubmit: 'phone.check.add', //号码检测提交


    smsSignList: 'sms.sign.list', //极速短信-短信签名及退订语列表
    smsTempList: 'app.cate.temp.list', //极速短信-短信模板列表
    smsSendCheck: 'app.sms.task.check', //极速短信-短信预发送
    smsSendAdd: 'app.sms.task.add', //极速短信-短信发送
    getTempCategoryList: 'comp.sms.cate.list', //获取模板类型列表
    getTempList: 'app.temp.list', //获取模板分页列表
    slinkDisplayList: 'slink.display.list', //获取失联修复外显号码列表
    qrCodeGet: 'qr.login.get', //扫码登录-web端获取二维码
    qrCodeStatus: 'qr.login.status', //扫码登录-web端轮询
    threeCheckList: 'three.records.list', //三要素验证-校验记录
    threeCheckSubmit: 'three.iden.submit', //三要素验证-提交验证
    toggleCollectTemp: 'sms.temp.collect', //极速短信-短信模板收藏/取消收藏
    smsSendBatchList: 'app.sms.send.batch.list', //极速短信-短信发送批次记录
    smsSendTaskCancel: 'app.sms.task.cancel', //极速短信-取消待发送的短信
    smsSendSingleList: 'app.sms.send.list', //极速短信-短信发送单条记录
    remindSmsTemps: 'call.notice.index', //去电提醒-模板列表
    remindSmsTempSet: 'call.notice.set', //去电提醒-设置

    customerTeamList: 'cust.group.treeList', //通讯录-客户分组-tree
    customerTeamAdd: 'cust.group.add',//通讯录-客户分组-新增
    customerTeamEdit: 'cust.group.edit', //通讯录-客户分组-编辑
    customerTeamDel: 'cust.group.delete', //通讯录-客户分组-删除
    customerContactList: 'cust.page.list', //通讯录-客户列表
    distributeMemberList: 'team.user.list', //【通讯录】组长查询团队下属成员
    customerBatchDel: 'cust.batch.delete', //【通讯录】批量删除客户
    customerImportNumber: 'cust.import.task.add', //【通讯录】提交批量导入任务（excel导入）

    callPhoneImportNumber: 'phone.check.detail.import', //号码检测结果批量导入到通讯录
    callPhoneExportNumber: 'phone.check.detail.export', //号码检测结果导出到Excel


    customerHandAddNumber: 'cust.new.add', //【通讯录】批量添加客户到通讯录
    customerImportTaskList: 'cust.import.task.list', //【通讯录】导入结果查询
    importResultsReport: 'cust.import.result.export', //【通讯录】客户导入结果导出
    customerMemberDistribute: 'cust.member.dist', //【通讯录】分配客户
    customerFollowList: 'cust.follow.list', //【通讯录】客户跟进信息列表
    customerInfoDetail: 'cust.info.detail', //【通讯录】客户详情
    customerEditDetail: 'cust.info.edit', //【通讯录】客户信息编辑
    callRecordAddRemark: 'call.log.addremark', //【通讯录】通话记录备注编辑
    customerFollowInfoSave: 'cust.follow.add', //【通讯录】新增跟进信息

    getCustTagsList:'comp.option.list',//获取全部的客户意向标签
}

// 全局请求封装
const http = async (method, data = {}, options = {}) => {
	// showLoading: 是否显示loading，默认不显示loading
    let hideLoading, { showLoading, loadingTitle } = options
    if (showLoading) {
        hideLoading = message.loading(loadingTitle || '加载中', 0)
    }
	const sidInStorage = localStorage.getItem(`${STORE_STATE_KEY}sid`)
    console.log('--------http参数： ' + JSON.stringify(data))
    let apiUrl = `${httpStaticInfo.proxyPathPrefix}/gateway`, // 使用代理解决跨域
        app_id = httpStaticInfo.appid,
		opts = Object.assign({
			showErrToast: true, // 请求失败或成功后 code !== 200 时，页面是否 toast 错误信息
			requestType: 'POST', // 请求方式
            directReturnData: false, //默认返回 data.result 设为true 直接返回 data
		}, options),
        // 测试 sid: 2c3cc8d62f3fb978d8aa0d8e3ead8da9 (100005)
        sid = sidInStorage ? sidInStorage : '', // 用户sid
        timestamp = +new Date + '',
        desData = encryptoByDESModeECB(Base64.encode(JSON.stringify(data)), httpStaticInfo.appKey),
        // sign = app_id + sid + method + timestamp + data
        sign = Base64.encode(md5(app_id + sid + method + timestamp + desData)),
        jsonData = {
            app_id,
            sid,
            method,
            timestamp,
            data: desData,
            sign
        },
		response;
	
	try{
		switch (opts.requestType.toUpperCase()) {
			case 'POST':
				response = await request.post(apiUrl, jsonData, opts)
				break
            case 'PUT':
                response = await request.put(apiUrl, jsonData, opts)
                break
			case 'GET':
				response = await request.get(apiUrl, {params: jsonData, ...opts})
				break
		}
		if (response.status === 200) {// 请求响应成功
		    // 接口返回成功
            if (opts.directReturnData) {
                return response.data
            }
		    if (response.data.success && response.data.code === 200 ) {
				opts.success && opts.success(response.data.result, response.data.message)
				showLoading && hideLoading()
		        return response.data.result
		    } else {
				if (response.data.code === 4000) {//操作失败，用户已过期
					// 设置登录状态、用户信息、sip信息
                    window.UA && window.UA.stop()
					localStorage.removeItem(`${STORE_STATE_KEY}sid`)
					localStorage.removeItem(`${STORE_STATE_KEY}validSidEndTime`)
					localStorage.removeItem(`${STORE_STATE_KEY}userInfo`)
                    window.location.href = '/login'
				}
				opts.fail && opts.fail(response.data)
				showLoading && hideLoading()
				opts.showErrToast && message.error(response.data.message)
				return null
		    }
		} else {
			opts.fail && opts.fail(response.data)
            showLoading && hideLoading()
            opts.showErrToast && message.error(response.data.message)
			return null
		}
	} catch(e) {
		opts.fail && opts.fail(e)
        showLoading && hideLoading()
		opts.showErrToast && message.error(e.data ? e.data.error : e.message ? e.message : e.toString())
		return null
	} finally {
		opts.complete && opts.complete()
	}
        
}

// 全局post请求
export const post = async (method, data = {}, options = {}) => {
	return await http(method, data, {
		...options,
		requestType: 'POST'
	})
}

// 全局put请求
export const put = async (method, data = {}, options = {}) => {
	return await http(method, data, {
		...options,
		requestType: 'PUT'
	})
}

// 全局get请求
export const get = async (method, data = {}, options = {}) => {
    return await http(method, data, {
		...options,
		requestType: 'GET'
	})
}

// 下载
export const download = async (url, params) => {
    return await request({
        url: url,
        method: 'get',
        responseType: 'blob',
        params
    })
}

// 进入系统请求 systemInfo
export const getSystemInfo = async () => {
    try {
        const result = await request.get(`${httpStaticInfo.proxyPathPrefix}/loadResource`)
        if (result.status === 200) {
            // 接口返回成功
		    if (result.data.success && result.data.code === 200 ) {
		        return result.data.result
		    } else {
				message.error(result.data.message)
				return Promise.reject(result.data)
		    }
        } else {
            message.error(result.data.message)
            return Promise.reject(result)
        }
    } catch (e) {
        message.error(e.data ? e.data.error : e.message ? e.message : e.toString())
        return Promise.reject(e)
    }
}
