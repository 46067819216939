import { 
    useState 
} from 'react'
import { 
    BrowserRouter as HistoryRouter
} from 'react-router-dom'
import { 
    ConfigProvider, 
    Spin,
    message, 
} from 'antd'

// lang zh_CN
import zhCN from 'antd/es/locale/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn') //时间组件默认中文

// message 全局配置
message.config({
    top: 120,
    maxCount: 3,
})

// getSystemInfo
import { getSystemInfo } from '@/api'

// store
import { Provider } from 'react-redux'
import store from './store'
import { setSystemInfo } from '@/store/actions/system'

// reset and common css
import './styles/reset.scss'
import './styles/common.scss'

// router config
import routes from './router/router.config'
// routers
import Routers from './router'

function App() {
    const { system: { systemInfo } } = store.getState()
    const [isLoading, setIsLoading] = useState(!systemInfo)
    
    // 如果 store 中未拿到 systemInfo，则先去拿 systemInfo 再加载对应页面
    if (!systemInfo) {
        getSystemInfo().then( system => {
            const systemInfo = JSON.parse(system)
            document.title = `${systemInfo.mainTitle}`
            store.dispatch(setSystemInfo(systemInfo))
            setIsLoading(false)
        })
    } else {
        document.title = `${systemInfo.mainTitle}`
    }

    return (
        <Provider store={store}>
            {/* antd 组件默认中文 */}
            <ConfigProvider locale={ zhCN }>
                <Choose>
                    <When condition={ !isLoading }>
                        <HistoryRouter>
                            <Routers routes={ routes } pathToChildRoute="" />
                        </HistoryRouter>
                    </When>
                    <Otherwise>
                        <div className="app_loading_container center_middle flex justify_content_center align_items_center">
                            <Spin size="large" />
                        </div>
                    </Otherwise>
                </Choose>
            </ConfigProvider>
        </Provider>
    )
}

export default App
