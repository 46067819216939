export const STORE_STATE_KEY = 'STORE_STATE_KEY:'

/**
 * @stateKey  {string}  redux state key
 * @actionType  {string} redux action type
 * @data  {any}  value that redux stateKey in state will set
 * @storage {sessionStorage|localStorage} set value into storage
 */
export default function getCommonAction(stateKey, actionType, data, storage) {
    storage && (
        data ? 
            storage?.setItem(`${STORE_STATE_KEY}${stateKey}`, typeof data === 'object' ? JSON.stringify(data) : data)
            : 
            storage?.removeItem(`${STORE_STATE_KEY}${stateKey}`)
    )
    return {
        type: actionType,
        data
    }
}