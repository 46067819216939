import {
    SET_USER_SID,
    SET_USER_INFO,
    SET_USER_SID_VALID_END_TIME
} from '../constants/user'
import { STORE_STATE_KEY } from '../common'

const sid = localStorage.getItem(`${STORE_STATE_KEY}sid`) || '',
    validSidEndTime = localStorage.getItem(`${STORE_STATE_KEY}validSidEndTime`) || '',
    userInfo = localStorage.getItem(`${STORE_STATE_KEY}userInfo`)

const INITIAL_STATE = {
    sid,
    validSidEndTime,
    userInfo: userInfo && JSON.parse(userInfo),
}

export default function user (state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_USER_SID:
            return {
                ...state,
                sid: action.data
            }
        case SET_USER_SID_VALID_END_TIME:
            return {
                ...state,
                validSidEndTime: action.data
            }
        case SET_USER_INFO:
            return {
                ...state,
                userInfo: action.data
            }
        default:
            return state
    }
}