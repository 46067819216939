import { lazy } from 'react'
import { withScope } from '@/higher-components'

/**
 * @path {String} 路由对应的url
 * @component {ReactComponent} 路由对应的组件
 * @unExact {undefined | Boolean} 路由是否非完全匹配，默认不设置，即路由需完全匹配
 * @unNeedLogin {undefined | Boolean} 路由是否不需要验证登录，默认不设置，即路由需验证登录
 * @children {undefined | Array} 对应子路由
 * @isPermission {undefined | Boolean} 路由是否为权限路由(即需要对应权限才能进入)，默认不设置
 * 
 * 注： 
 * 1、带 children 的路由 exact 一定不能设置为 true （即需设置 unExact 为 true ），否则无法渲染
 */
const routes = [
    {
        path: '/404',
        component: lazy(() => import('@/views/NotFound')),
        name: '抱歉！页面未找到~',
        unNeedLogin: true
    },
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        component: lazy(() => import('@/views/Login')),
        name: '登录',
        unNeedLogin: true
    },
    {
        path: '/main',
        component: lazy(() => import('@/views/Main')),
        unExact: true,
        children: [
            {
                path: '/index',
                component: lazy(() => import('@/views/main/MainRouterView')),
                name: '通话统计',
                isPermission: true,
                unExact: true,
                children: [
                    {
                        path: '/call-statistics',
                        component: lazy(() => import('@/views/main/CallStatistics/Index')),
                        name: '通话统计'
                    },
                    {
                        path: '/call-record-list',
                        component: lazy(() => import('@/views/main/CallStatistics/RecordList')),
                        name: '通话记录',
                    }
                ]
            },
            {
                path: '/lose-contact-fix',
                component: lazy(() => import('@/views/main/MainRouterView')),
                name: '失联修复',
                isPermission: true,
                unExact: true,
                children: [
                    {
                        path: '/index',
                        component: lazy(() => import('@/views/main/LoseContactFix/Index')),
                        name: '失联修复'
                    },
                    {
                        path: '/combos-buy',
                        component: withScope(lazy(() => import('@/components/ComboBuy/Index')), { productCode: 102 }),
                        name: '套餐充值',
                    },
                    {
                        path: '/trader-record-list',
                        component: withScope(lazy(() => import('@/components/TradeRecordList/Index')), { productCode: 102 }),
                        name: '交易记录',
                    }
                ]
            },
            {
                path: '/fast-sms',
                component: lazy(() => import('@/views/main/MainRouterView')),
                name: '极速短信',
                isPermission: true,
                unExact: true,
                children: [
                    {
                        path: '/index',
                        component: lazy(() => import('@/views/main/FastSMS/Index')),
                        name: '极速短信'
                    },
                    {
                        path: '/temp-list',
                        component: lazy(() => import('@/views/main/FastSMS/TempList')),
                        name: '短信模板',
                    },
                    {
                        path: '/send-records',
                        component: lazy(() => import('@/views/main/FastSMS/sendRecords')),
                        name: '发送记录',
                    },
                    {
                        path: '/combos-buy',
                        component: withScope(lazy(() => import('@/components/ComboBuy/Index')), { productCode: 100 }),
                        name: '套餐充值',
                    },
                    {
                        path: '/trader-record-list',
                        component: withScope(lazy(() => import('@/components/TradeRecordList/Index')), { productCode: 100 }),
                        name: '交易记录',
                    }
                ]
            },
            {
                path: '/empty-phone-check',
                component: lazy(() => import('@/views/main/MainRouterView')),
                name: '空号检测',
                isPermission: true,
                unExact: true,
                children: [
                    {
                        path: '/index',
                        component: lazy(() => import('@/views/main/EmptyPhoneCheck/Index')),
                        name: '空号检测'
                    },
                    {
                        path: '/combos-buy',
                        component: withScope(lazy(() => import('@/components/ComboBuy/Index')), { productCode: 101 }),
                        name: '套餐充值',
                    },
                    {
                        path: '/trader-record-list',
                        component: withScope(lazy(() => import('@/components/TradeRecordList/Index')), { productCode: 101 }),
                        name: '交易记录',
                    }
                ]
            },
            {
                path: '/three-elements-check',
                component: lazy(() => import('@/views/main/MainRouterView')),
                name: '三要素验证',
                isPermission: true,
                unExact: true,
                children: [
                    {
                        path: '/index',
                        component: lazy(() => import('@/views/main/ThreeElementsCheck/Index')),
                        name: '三要素验证'
                    },
                    {
                        path: '/combos-buy',
                        component: withScope(lazy(() => import('@/components/ComboBuy/Index')), { productCode: 103 }),
                        name: '套餐充值',
                    },
                    {
                        path: '/trader-record-list',
                        component: withScope(lazy(() => import('@/components/TradeRecordList/Index')), { productCode: 103 }),
                        name: '交易记录',
                    }
                ]
            },
            {
                path: '/contact-list',
                component: lazy(() => import('@/views/main/ContactList/Index')),
                name: '通讯录'
            },
            {
                path: '/crm-customer',
                component: lazy(() => import('@/views/main/CrmRecord/Index')),
                name: '客户CRM'
            },
            {
                path: '/call-phone-check',
                component: lazy(() => import('@/views/main/CallPhoneCheck/Index')),
                name: '号码检测'
            },
            {
                path: '/combo-buy/:packageId',
                component: lazy(() => import('@/views/main/ComboBuyDetail')),
                name: '购买套餐',
            },
            {
                path: '/pay-result',
                component: lazy(() => import('@/views/main/PayResult')),
                name: '支付成功',
            },
            {
                path: '/batch-global-call',
                component: lazy(() => import('@/views/main/BatchGlobalCall')),
                name: '批量拨号',
            },
        ]
    },
]

export default routes