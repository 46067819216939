import {
    SET_SYSTEM_INFO
} from '../constants/system'
import getCommonAction from '../common'

// 用户进入系统时设置 systemInfo
export const setSystemInfo = (data) => {
    console.log('setSystemInfo action: ', data)
    return getCommonAction('systemInfo', SET_SYSTEM_INFO, data, sessionStorage)
}
