import { createContext } from 'react'
import TransitionAppear from '@/components/JsSipVoiceRing/TransitionAppear'

// 组件快捷传参
export const withScope = (WrappedComponent, params) => props => (
    <WrappedComponent {...props} {...params} />
)

// 组件过渡效果
export const withTransition = (
    Component, 
    params = {}
) => props => (
    <TransitionAppear 
        transitionName={ params.transitionName }
        duration={ params.duration || 600 }> 
        <div 
            className={ params.className }
            transition-component="transition-component">
            <Component {...props} />
        </div>
    </TransitionAppear>
)

// 跨组件传值 ---- start ----
const GlobalContext = createContext()
GlobalContext.displayName = "GlobalContext"
const { Provider, Consumer } = GlobalContext
/**
 * @value {any} 跨组件注入的值，必传
 */
export const ReactProvider = ({value, children}) => <Provider value={value} children={children} />
/**
 * @consumerValuePropName {undefined|string}
 *      注：若传入 @consumerValuePropName ，则传入的属性名为 @consumerValuePropName ，且取对应 consumerValue 中字段名为 @consumerValuePropName 的属性值；
 *      若不传入 @consumerValuePropName ，则属性名为默认值 "consumerValue" ，且直接取 consumerValue 为传入的值
 */
export const ReactConsumer = (WrappedComponent, consumerValuePropName) => props => (
    <Consumer>
        {
            consumerValue => (
                <WrappedComponent 
                    {...props} 
                    {...{
                        [consumerValuePropName || 'consumerValue']: consumerValuePropName ? consumerValue[consumerValuePropName] : consumerValue
                    }} />
            )
        }
    </Consumer>
)
// 跨组件传值 ---- end ----
